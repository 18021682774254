/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountRole } from './accountRole';
import { UserEmailSettings } from './userEmailSettings';


export interface User { 
    /**
     * If this user is associated with multiple accounts then this value will be one of those accounts.
     * @deprecated
     */
    account_id: string;
    /**
     * The user\'s role within a specific account.
     */
    account_roles: Array<AccountRole>;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * The user email.
     */
    email: string;
    /**
     * User email settings.
     */
    email_settings: UserEmailSettings;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Time at which the user last successfully logged in via the API.
     */
    last_login_at?: string;
    /**
     * Time at which the user\'s token was last used to authenticate a request to the API.
     */
    last_request_at?: string;
    /**
     * The full name of the user.
     */
    name: string;
    /**
     * If this user is associated with multiple accounts then this value will be the role associated with the account_id property. The user\'s role, primarily used in the Tilled Dashboard. The roles have varied restrictions on the actions they can take.
     * @deprecated
     */
    role: User.RoleEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * Six digit verification code of the user.
     */
    verification_code?: string;
}
export namespace User {
    export type RoleEnum = 'owner' | 'admin' | 'developer' | 'analyst' | 'view_only' | 'merchant_owner' | 'merchant_admin' | 'reseller_owner' | 'reseller_admin' | 'reseller_view_only';
    export const RoleEnum = {
        OWNER: 'owner' as RoleEnum,
        ADMIN: 'admin' as RoleEnum,
        DEVELOPER: 'developer' as RoleEnum,
        ANALYST: 'analyst' as RoleEnum,
        VIEW_ONLY: 'view_only' as RoleEnum,
        MERCHANT_OWNER: 'merchant_owner' as RoleEnum,
        MERCHANT_ADMIN: 'merchant_admin' as RoleEnum,
        RESELLER_OWNER: 'reseller_owner' as RoleEnum,
        RESELLER_ADMIN: 'reseller_admin' as RoleEnum,
        RESELLER_VIEW_ONLY: 'reseller_view_only' as RoleEnum
    };
}


