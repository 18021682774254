/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminProcessorEmailParams { 
    document_ids: Array<string>;
    recipient: string;
    recipient_note?: string;
    type?: AdminProcessorEmailParams.TypeEnum;
}
export namespace AdminProcessorEmailParams {
    export type TypeEnum = 'paysafeActive' | 'paysafePended' | 'tsysActive' | 'tsysPended' | 'northPended';
    export const TypeEnum = {
        PAYSAFE_ACTIVE: 'paysafeActive' as TypeEnum,
        PAYSAFE_PENDED: 'paysafePended' as TypeEnum,
        TSYS_ACTIVE: 'tsysActive' as TypeEnum,
        TSYS_PENDED: 'tsysPended' as TypeEnum,
        NORTH_PENDED: 'northPended' as TypeEnum
    };
}


