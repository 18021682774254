import {
  AccountDocument,
  AdminDocument,
  AdminProcessorEmailParams,
  AdminService,
} from '@admin-api/index';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';

export interface IEmailProcessorDialogReturnData {
  emailRecipient: string;
  emailRecipientType: AdminProcessorEmailParams.TypeEnum;
  requestNotes?: string;
  documents: string[];
}

interface IViewEmailProcessorDialogData {
  accountId: string;
}

@Component({
  selector: 'app-email-processor-dialog',
  templateUrl: './email-processor-dialog.component.html',
  styleUrls: ['./email-processor-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmailProcessorDialogComponent {
  public accountId: string;
  public emailRecipientForm: UntypedFormGroup;
  public noteForm: UntypedFormGroup;
  public documentForm: UntypedFormGroup;
  public verifiedDocuments: AdminDocument[];
  public verifiedDocumentCount: number;
  public noDocumentsSelected: boolean = false;

  public subtypeToTitle = new Map<AccountDocument.SubtypeEnum, string>([
    [
      AccountDocument.SubtypeEnum.BANK_LETTER_VOIDED_CHECK,
      'Bank Letter or Voided Check',
    ],
    [AccountDocument.SubtypeEnum.EIN_LETTER, 'EIN Letter'],
    [
      AccountDocument.SubtypeEnum.ARTICLES_OF_INCORP,
      'Articles of Incorporation',
    ],
    [AccountDocument.SubtypeEnum.BUSINESS_LICENSE, 'Business License'],
    [AccountDocument.SubtypeEnum.UTILITY_BILL, 'Utility Bill'],
    [
      AccountDocument.SubtypeEnum.PROCESSING_STATEMENT,
      'Processing Statement (past 3 - 6 months)',
    ],
    [
      AccountDocument.SubtypeEnum.BANK_STATEMENT,
      'Bank Statements (past 3 - 6 months)',
    ],
    [
      AccountDocument.SubtypeEnum.FINANCIAL_STATEMENT,
      'Financial Statement (past 1 - 2 years, include P&L Statement and Balance Sheet)',
    ],
    [AccountDocument.SubtypeEnum._501C3, '501c3'],
    [AccountDocument.SubtypeEnum.TAX_RETURN, 'Tax Return'],
    [AccountDocument.SubtypeEnum.INVOICE, 'Invoice'],
    [AccountDocument.SubtypeEnum.DL_PASSPORT, 'Driver’s License/Passport'],
    [AccountDocument.SubtypeEnum.DL_BACK, 'Driver’s License - Back side'],
    //written
    [AccountDocument.SubtypeEnum.BUSINESS_MODEL, 'Business model details'],
    [AccountDocument.SubtypeEnum.PRICING_MODEL, 'Pricing model details'],
    [AccountDocument.SubtypeEnum.WEBSITE, 'Website'],
    [AccountDocument.SubtypeEnum.SHOPPING_CART, 'No shopping cart'],
    [AccountDocument.SubtypeEnum.OTHER, 'Other (requires additional notes)'],
  ]);

  constructor(
    public matDialogRef: MatDialogRef<EmailProcessorDialogComponent>,
    private _adminService: AdminService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public readonly dialogData: IViewEmailProcessorDialogData,
  ) {
    this.accountId = dialogData.accountId;
    this.emailRecipientForm = this.fb.group({
      emailRecipientType: 'paysafePended',
      tsysPendedCustomRecipient: new UntypedFormControl({
        value: '',
        disabled: true,
      }),
      tsysActiveCustomRecipient: new UntypedFormControl({
        value: '',
        disabled: true,
      }),
    });
    this.noteForm = this.fb.group({
      recipientNote: new UntypedFormControl(''),
    });
    this.documentForm = this.fb.group({
      documents: new UntypedFormArray([]),
    });
  }

  ngOnInit(): void {
    this.retrieveFilteredDocuments();
  }

  retrieveFilteredDocuments() {
    let offset = 0;
    let limit = 100;
    this.retrieveDocuments(this.accountId, offset, limit);
  }

  get documentsFormArray() {
    return this.documentForm.controls.documents as UntypedFormArray;
  }

  retrieveDocuments(accountId: string, offset = 0, limit) {
    this._adminService
      .adminListDocuments({
        accountId,
        status: AdminDocument.StatusEnum.VERIFIED,
        offset,
        limit,
      })
      .subscribe(
        (res) => {
          this.verifiedDocuments = res.items;
          this.verifiedDocuments.forEach(() =>
            this.documentsFormArray.push(new UntypedFormControl()),
          );
          this.verifiedDocumentCount = res.total;
        },
        (error) => {
          //TODO handle the error case
        },
      );
  }

  public cancelClicked() {
    this.matDialogRef.close(null);
  }

  public onItemChange(event: MatRadioChange) {
    if (!event?.value) {
      return;
    }
    if (event.value === 'tsysPended') {
      this.emailRecipientForm.get('tsysPendedCustomRecipient')?.enable();
      this.emailRecipientForm
        .get('tsysPendedCustomRecipient')
        ?.setValidators([
          Validators.required,
          Validators.email,
          this.verifyTsysEmail(),
        ]);
      this.emailRecipientForm
        .get('tsysPendedCustomRecipient')
        .updateValueAndValidity();
      this.emailRecipientForm.get('tsysActiveCustomRecipient')?.disable();
      this.emailRecipientForm.get('tsysActiveCustomRecipient')?.reset();
      return;
    }
    if (event.value === 'tsysActive') {
      this.emailRecipientForm.get('tsysPendedCustomRecipient')?.disable();
      this.emailRecipientForm.get('tsysPendedCustomRecipient')?.reset();
      this.emailRecipientForm.get('tsysActiveCustomRecipient')?.enable();
      this.emailRecipientForm
        .get('tsysActiveCustomRecipient')
        ?.setValidators([
          Validators.required,
          Validators.email,
          this.verifyTsysEmail(),
        ]);
      this.emailRecipientForm
        .get('tsysActiveCustomRecipient')
        .updateValueAndValidity();
      return;
    }
    this.emailRecipientForm.get('tsysPendedCustomRecipient')?.reset();
    this.emailRecipientForm.get('tsysActiveCustomRecipient')?.reset();
    this.emailRecipientForm.get('tsysPendedCustomRecipient')?.disable();
    this.emailRecipientForm.get('tsysActiveCustomRecipient')?.disable();
    this.emailRecipientForm
      .get('tsysPendedCustomRecipient')
      ?.setValidators(null);
    this.emailRecipientForm
      .get('tsysActiveCustomRecipient')
      ?.setValidators(null);
  }

  public verifyTsysEmail(): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const value = control.value;
      if (/@tsys.com\s*$/.test(value) || /@globalpay.com\s*$/.test(value)) {
        return null;
      }

      return { invalidTsysEmail: true };
    };
  }

  public getSubmittedTime(document: AdminDocument) {
    return this.getStatusHistoryDate(
      document,
      AdminDocument.StatusEnum.SUBMITTED,
    );
  }

  public getVerifiedTime(document: AdminDocument) {
    return this.getStatusHistoryDate(
      document,
      AdminDocument.StatusEnum.VERIFIED,
    );
  }

  public getStatusHistoryDate(
    document: AdminDocument,
    subtype: AdminDocument.StatusEnum,
  ) {
    if (document.status_history?.length > 0) {
      for (const history of document.status_history) {
        if (history.status === subtype) {
          return history.status_date;
        }
      }
    }
  }

  public submitClicked() {
    if (
      this.emailRecipientForm.invalid ||
      this.noteForm.invalid ||
      this.documentForm.invalid
    ) {
      return;
    }
    const selectedDocuments = this.documentForm.value.documents
      .map((checked, i) => (checked ? this.verifiedDocuments[i].id : null))
      .filter((v) => v !== null);

    if (!selectedDocuments || selectedDocuments.length === 0) {
      this.noDocumentsSelected = true;
      return;
    }
    const emailRecipientType = this.emailRecipientForm.value.emailRecipientType;
    const returnData: IEmailProcessorDialogReturnData = {
      emailRecipient: this.setEmailRecipient(emailRecipientType),
      emailRecipientType: emailRecipientType,
      requestNotes: this.noteForm.value.recipientNote,
      documents: selectedDocuments,
    };
    this.matDialogRef.close(returnData);
  }

  setEmailRecipient(emailType: string): string {
    switch (emailType) {
      case AdminProcessorEmailParams.TypeEnum.PAYSAFE_PENDED:
        return 'platformonboarding@paysafe.com';
      case AdminProcessorEmailParams.TypeEnum.PAYSAFE_ACTIVE:
        return 'partnersupport@paysafe.com';
      case AdminProcessorEmailParams.TypeEnum.TSYS_PENDED:
        return this.emailRecipientForm.get('tsysPendedCustomRecipient').value;
      case AdminProcessorEmailParams.TypeEnum.TSYS_ACTIVE:
        return this.emailRecipientForm.get('tsysActiveCustomRecipient').value;
      case AdminProcessorEmailParams.TypeEnum.NORTH_PENDED:
        return 'smb@merchunderwriting.com';
      default:
        return '';
    }
  }
}
