/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminNorthPlanId { 
    id: string;
    plan_id: string;
    pricing_type: AdminNorthPlanId.PricingTypeEnum;
    reseller_account_id?: string;
}
export namespace AdminNorthPlanId {
    export type PricingTypeEnum = 'flat_rate' | 'interchange';
    export const PricingTypeEnum = {
        FLAT_RATE: 'flat_rate' as PricingTypeEnum,
        INTERCHANGE: 'interchange' as PricingTypeEnum
    };
}


